import React from "react";

const NotFound = () => {
  return (
    <h1 style={{ marginTop: "8rem" }} className="nothingText">
      Sorry, 404 Not Found
    </h1>
  );
};

export default NotFound;
